/* Navbar.css */
.navbar {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  color: white;
  height: 80px;
  padding: 0 20px;
}

.navbar-link {
  color: white;
  text-decoration: none;
  margin-left: 20px;
  display: flex;
  align-items: center;
}

.navbar-links {
  display: flex;
  gap: 20px;
}

.navbar-logo {
  height: 40px;
  margin-left: 20px;
}

.title {
  display: flex;
  align-items: center;
}

.navbar-link img {
  display: inline-block;
}

.navbar-link h4 {
  display: inline-block;
  margin-left: 10px;
}

.menu-icon {
  cursor: pointer;
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  padding: 0;
  z-index: 10;
  overflow: hidden;
}

.menu-icon div {
  width: 2rem;
  height: 0.25rem;
  background: white;
  border-radius: 10px;
  transition: all 0.3s linear;
  position: relative;
  transform-origin: 1px;
}

/* Responsive styles for tablets */
@media (min-width: 601px) and (max-width: 900px) {
  .navbar {
    padding: 0 10px;
  }

  .navbar-link {
    margin-left: 10px;
  }

  .navbar-logo {
    height: 30px;
    margin-left: 10px;
  }
}

/* Responsive styles for mobile */
@media (max-width: 600px) {
  .navbar {
    padding: 0 5px;
  }

  .navbar-link {
    margin-left: 5px;
  }

  .menu-icon {
    display: flex;
  }

  .navbar-links {
    position: absolute;
    background: black;
    background-color: black;
    width: 4rem;
    top: 80px; /* height of the navbar */
    padding-right: 10px;
    flex-direction: column;
    align-items: flex-end;
    display: none;
    right: 0; /* align to the right side of the screen */
  }

  .navbar-links.show {
    display: flex;
  }

  .navbar-logo {
    height: 20px;
    margin-left: 5px;
  }
}
