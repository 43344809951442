@keyframes glideInBottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

.form-container {
  position: relative;
  z-index: 1;
  width: 50%;
  margin: 0 auto;
  text-align: center;
  animation: glideInBottom 2s ease-out forwards;
}

.input-button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.email-form-button {
  background-color: #4682b4;
  color: white; /* White text color */
  border: none; /* Remove default button border */
  border-radius: 4px;
  padding: 10px 20px;
  text-align: center; /* Center the text */
  text-decoration: none; /* Remove default text decoration */
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer; /* Change cursor to pointer when hovering over the button */
  transition: background-color 0.3s ease; /* Add a transition effect */
  font-size: large;
}

.email-form-button:hover {
  background-color: #356f9f; /* Darken the background color when hovering over the button */
}

.email-form-input {
  border: 1px solid #ccc;
  border-radius: 4px; /* Add rounded edges */
  padding: 10px 20px;
  margin: 4px 8px;
  font-size: large;
}

.success-message {
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 400px) {
  .form-container {
    flex-direction: column;
  }
}
