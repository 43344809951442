@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.navbar {
  position: relative;
  z-index: 1000;
}

.paragraph-container {
  position: relative;
  z-index: 1;
  width: 50%;
  margin: 0 auto;
  text-align: center;
  animation: fadeIn 2s ease-in forwards;
}

footer {
  z-index: 1;
}

/* For Chrome, Safari, and Opera */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #000;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(
    to bottom,
    transparent,
    #4682b4,
    transparent
  ); /* Fading effect */
}

::-webkit-scrollbar-thumb:hover {
  background: #356f9f;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.container {
  z-index: 1;
  min-height: 100vh;
}

.main-content {
  flex-grow: 1;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  background-color: black;
  font-size: x-large;
  scrollbar-width: thin;
  scrollbar-color: #4682b4 transparent;
}

@media (min-width: 601px) and (max-width: 900px) {
  body {
    font-size: x-large;
  }

  .paragraph-container {
    width: 80%;
    animation: fadeIn 2s ease-in forwards;
  }
}
@media screen and (max-width: 600px) {
  body {
    font-size: medium;
  }

  .paragraph-container {
    width: 80%;
    animation: fadeIn 2s ease-in forwards;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
