@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.card-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin: 10px;
  margin-top: 250px;
  animation: fadeIn 2s ease-in forwards;
}

.card-child {
  width: 50%;
  border-radius: 10px;
  z-index: 1;
}

.left-card-content {
  margin-left: 50px;
  z-index: 1;
}

.right-card-content {
  margin-right: 50px;
  z-index: 1;
}

/* Responsive styles for tablets */
@media (min-width: 601px) and (max-width: 900px) {
  .left-card-content {
    margin-left: 20px;
    z-index: 1;
  }

  .right-card-content {
    margin-right: 20px;
    z-index: 1;
  }
}

/* Responsive styles for mobile */
@media (max-width: 600px) {
  .card {
    display: flex;
    justify-content: left;
    padding: 1px;
    margin-top: 100px;
    animation: fadeIn 2s ease-in forwards;
  }

  .left-card-content {
    text-align: center;
    margin-left: 10px;
    z-index: 1;
  }

  .right-card-content {
    text-align: center;
    margin-right: 10px;

    z-index: 1;
  }
}
