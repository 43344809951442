@keyframes glideInTop {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes glideInBottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

.lamp-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 40vh; /* Adjust as needed */
  position: relative;
  overflow: hidden;
}

.lamp {
  position: absolute;
  top: 0;
  left: 50;
  width: 30%;
  height: 80%; /* Adjust as needed */
  background: linear-gradient(to bottom, white, transparent 90%);
  filter: blur(50px);
  animation: glideInTop 2s ease-out forwards;
}

.lamp-img {
  max-width: 70%;
  max-height: 70%;
  z-index: 1;
  animation: glideInBottom 2s ease-out forwards;
}

/* Responsive styles for tablets */
@media (min-width: 601px) and (max-width: 900px) {
  .container {
    height: 60vh;
  }

  .lamp {
    width: 40%;
    height: 80%;
  }
}

/* Responsive styles for mobile */
@media (max-width: 600px) {
  .container {
    height: 40vh;
  }

  .lamp {
    width: 50%;
    height: 80%;
  }
}
